import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  CONFIG_OPTION_TOAST_ERROR
} from "common/toast";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import { COLOR_STATUS_COLLECT_EMAIL, STATUS_COLLECT_EMAIL_OPTION } from "helpers/constans";
import { formatDateStringToKorea } from "helpers/format";
import CountUp from "react-countup";
import { downloadExcelCollectedEmails, getCollectedEmails as onGetCollectedEmails } from "store/collect/thunk";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "created_at";

registerLocale("en", en);
registerLocale("ko", ko);

const CollectedEmailList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const STATUS_COLLECT_EMAIL_OPTION_LANG = STATUS_COLLECT_EMAIL_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];


  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    keyword: withDefault(StringParam, ""),
    status: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ''),
    end_date: withDefault(StringParam, ''),
  });

  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [keywordSearch, setKeywordSearch] = useState<string>(query['condition[title]'] || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_COLLECT_EMAIL_OPTION_LANG?.find((item: any) => String(item?.value) === String(query?.status)) || null);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date || "", 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || "", 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Collect;

  const CollectProperties = createSelector(selectLayoutState, (state) => ({
    collectedEmails: state.collectedEmails,
    isCollectedEmailLoading: state.isCollectedEmailLoading,
    error: state.error,
  }));

  // Inside your component
  const { collectedEmails, isCollectedEmailLoading } = useSelector(CollectProperties);

  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch || '',
      status: statusSearch?.value ?? '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        keyword: '',
        status: '',
        start_date: '',
        end_date: '',
        sort_by: TYPE_SELECT_DEFAULT,
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setStatusSearch((_prev) => null);
    setDateSearch((_prev) => [null, null]);
  };

  useEffect(() => {
    dispatch(onGetCollectedEmails(query));
  }, [JSON.stringify(query)]);


  useEffect(() => {
    const handleCallAllOption = async () => {

    };

    handleCallAllOption();
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  // Begin::Change status
  const [itemUpdate, setItemUpdate] = useState<{ id: number | string, status: string | boolean } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdate = (item: { id: number | string, status: string | boolean }) => {
    setItemUpdate(item);
  };
  const handleOnConfirmUpdate = async () => {
    try {
      // if (!itemUpdate) {
      //   return;
      // }
      // setIsLoadingUpdate((_prev) => true);
      // const response: any = await PostApi?.updatePost(itemUpdate?.id, { status: itemUpdate?.status });
      // setIsLoadingUpdate((_prev) => false);
      // if (response?.success) {
      //   setItemUpdate((_prev) => null);
      //   loadTableData();
      //   toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      // } else {
      //   toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      // }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End::Change status

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        Cell: ({ ...props }) => {
          return (
            <div>
              {collectedEmails?.meta?.total - ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      // {
      //   Header: t("Active"),
      //   accessor: "status",
      //   thWidth: 180,
      //   filterable: false,
      //   sortable: false,
      //   thClass: 'text-center',
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (
      //       <div className="form-check form-switch form-switch-sm text-center" dir="ltr" style={{ width: '130px' }}>
      //         <input
      //           type="checkbox"
      //           className="form-check-input"
      //           name="status"
      //           id="customSwitchsizelg"
      //           checked={String(item?.status) === ENUM_STATUS_COLLECT_EMAIL.ACTIVATED}
      //           onChange={(e) => {
      //             const value = e?.target?.checked;
      //             handleShowConfirmUpdate({ id: item?._id, status: !!value ? ENUM_STATUS_FEED.ACTIVATED : ENUM_STATUS_FEED.DISABLED });
      //           }}
      //         />
      //       </div>
      //     );
      //   },
      // },
      {
        Header: t('Status'),
        accessor: "_status",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span className={`rounded-pill badge bg-${COLOR_STATUS_COLLECT_EMAIL[status] || 'secondary'}`}>{STATUS_COLLECT_EMAIL_OPTION_LANG?.find((item: any) => String(item?.value) === String(status))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div style={{ minWidth: '200px' }}>{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("OTP"),
        accessor: "code",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center text-primary" style={{ minWidth: '100px' }}>
                <strong>{cell?.value}</strong>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Sending OTP Date"),
        accessor: "registered_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");
          return (
            <div className="text-end" style={{ minWidth: '120px' }}>
              {!!cell?.value ? <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span> : <>-</>}
            </div>
          );
        },
      },

      {
        Header: t("Verify OTP Date"),
        accessor: "verified_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");
          return (
            <div className="text-end" style={{ minWidth: '120px' }}>
              {!!cell?.value ? <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span> : <>-</>}
            </div>
          );
        },
      },
      {
        Header: t("Expired Date"),
        accessor: "expired_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");
          return (
            <div className="text-end" style={{ minWidth: '120px' }}>
              {!!cell?.value ? <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span> : <>-</>}
            </div>
          );
        },
      },
      {
        Header: t("Collect Date"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");
          return (
            <div className="text-end" style={{ minWidth: '120px' }}>
              {!!cell?.value ? <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span> : <>-</>}
            </div>
          );
        },
      },
      // {
      //   Header: t("Created at"),
      //   accessor: "created_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 130,
      //   thClass: "text-end",
      //   Cell: (cell: any) => {
      //     const date = formatDateStringToKorea(cell?.value)
      //     const arrDate = String(date || "").split(" ");

      //     return (
      //       <div className="text-end" style={{ minWidth: '120px' }}>
      //         <span>
      //           <span>{arrDate?.[0] || ""}</span> <br />{" "}
      //           <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: t("Updated at"),
      //   accessor: "updated_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 130,
      //   thClass: "text-end",
      //   Cell: (cell: any) => {
      //     const date = formatDateStringToKorea(cell?.value)
      //     const arrDate = String(date || "").split(" ");

      //     return (
      //       <div className="text-end" style={{ minWidth: '120px' }}>
      //         <span>
      //           <span>{arrDate?.[0] || ""}</span> <br />{" "}
      //           <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
    ],
    [i18n?.language, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await downloadExcelCollectedEmails({ ...query, page: 1, export_type: 'excel' });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data?.url;
        link.download = 'keywords';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // Form

  useEffect(() => {
    document.title = `${t("Collected Emails")} - ${t("Collected Emails")} | Dfesta`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Collected Emails")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-5 col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={collectedEmails?.meta?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                    <div className="col-7 text-end col-sm-auto">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleDownloadExcel}
                        disabled={isLoadingExportFile}
                      >
                        {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                        <span className="ms-1">{t('Button Download Excel')}</span>
                      </button>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <Col md={12} xl={12}>
                    <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics justify-content-start mb-3 mb-xl-2 position-relative" style={{ maxWidth: '100%', padding: '10px 5px 5px' }}>
                      <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                        <CardBody className="p-3">
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{STATUS_COLLECT_EMAIL_OPTION_LANG[0]?.label}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                            <div>
                              <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isCollectedEmailLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={collectedEmails?.meta?.total_verify || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                        <CardBody className="p-3">
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{STATUS_COLLECT_EMAIL_OPTION_LANG[1]?.label}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                            <div>
                              <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isCollectedEmailLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={collectedEmails?.meta?.total_not_verify || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                        <CardBody className="p-3">
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{STATUS_COLLECT_EMAIL_OPTION_LANG[2]?.label}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                            <div>
                              <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isCollectedEmailLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={collectedEmails?.meta?.total_expired || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                  <Row className="g-4 align-items-center mt-0 mt-md-2">
                    <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-2">
                      <LabelWrapper
                        label={t("Keyword")}
                        isShow={!!keywordSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Keyword")}...`}
                          value={keywordSearch}
                          onChange={(e) => setKeywordSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={12} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                      <LabelWrapper label={t('Status')} isShow={true}>
                        <DropdownStatus
                          name="status"
                          dataList={STATUS_COLLECT_EMAIL_OPTION_LANG || []}
                          placeholder={`${t("Status")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={statusSearch || null}
                          onChangeSelect={(e: any) => setStatusSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t('All'), value: '' }}
                          colors={COLOR_STATUS_COLLECT_EMAIL}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                      <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                        <DatePickerCustom
                          placeholder={t('Date')}
                          startDate={startDate || null}
                          endDate={endDate || null}
                          onChangePicker={handleChangePicker}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col
                      sm={12} md={12} xl={3} xxl={3}
                      className="hstack gap-1 justify-content-sm-center justify-content-md-end justify-content-xl-start mt-sm-4 mt-md-3"
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isCollectedEmailLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={collectedEmails?.list?.length ? collectedEmails?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={collectedEmails?.meta?.total}
                      customPageCount={Math.ceil(
                        Number(collectedEmails?.meta?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isCollectedEmailLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* <ModalConfirm
          textButtonConfirm={t('Button Update')}
          classButtonConfirm='btn-primary'
          classIconButtonConfirm=''
          header={(String(itemUpdate?.status) === ENUM_STATUS_FEED.ACTIVATED) ? t('Confirm to ON') : t('Confirm to OFF')}
          title={t('Are you sure you want to update this?')}
          content={t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
          isShowIcon={false}
          isOpen={!!itemUpdate}
          isLoading={isLoadingUpdate}
          onClose={() => setItemUpdate((prev: any) => null)}
          onConfirm={handleOnConfirmUpdate}
        /> */}
      </div >
    </React.Fragment >
  );
};

export default CollectedEmailList;
